import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "sv-automation-overview",
  "title": "Intelligent automation",
  "seo": {
    "title": "Automation",
    "descr": "-"
  },
  "bg": "/img/bg-automation.jpg",
  "summaryList": [{
    "text": "Intelligent Automation är ett område inom AI (Artificiell Intelligens) som innefattar ett flertal begrepp såsom RPA (Robotic Process Automation), Machine Learning (maskininlärning), Deep Learning, Computer Vision (bildigenkänning), NLP (naturlig språkförståelse), Chatbotar och andra teknologier. Dessa kombinerade utgör en stark grund för automatisering och bidrar till smartare och mer kraftfull teknik. Intelligent Automation håller på att förändra sättet som företag och organisationer drivs på. Med utkomst från digitaliseringen som pågått i många år är Intelligent Automation nästa steg på resan."
  }, {
    "text": "Det huvudsakliga syftet med Intelligent Automation är att förbättra medarbetar- och kundupplevelsen samtidigt som produktiviteten ökar. När företag och organisationer automatiserar repetitiva och monotona arbetsuppgifter, så får medarbetare möjligheten att utföra mer utvecklande och kreativa arbetsuppgifter såsom strategiska aktiviteter eller kundrelaterade uppgifter. Detta bidrar till att kunden får kortare ledtid och bättre respons från företaget. Förutom att Intelligent Automation leder till tid- och kostnadsbesparingar så bidrar det även till en ökad kvalitet, kapacitet och effektivitet."
  }],
  "pink": {
    "title": "Kom igång!",
    "text": "När processerna har valts ut börjar förändringsarbetet. Följ med in i en värld av nya tekniska möjligheter, skräddarsydda efter era behov. Kontakta oss här nedanför!",
    "buttonText": "Kontakta oss",
    "buttonPath": "contact"
  },
  "endSection": {
    "title": "Våra assistenter",
    "icon": "/img/cost.png",
    "buttonText": "Gå till",
    "buttonPath": "digitalassistants"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      